import {GenericOAuthService} from "@/domains/auth/services/genericOAuth.service";
import {type HandlingOAuthCredentialsFn} from "@/domains/auth/types";

export class SGSOAuthService extends GenericOAuthService {
  constructor(handleCredentialsFn: HandlingOAuthCredentialsFn | null = null) {
    super("oidc.sgs_oplit_oidc", handleCredentialsFn);

    this.provider.addScope("openid");
    this.provider.addScope("profile");
  }
}
