import type {UserCredential} from "firebase/auth";
import type {AsyncResult} from "@oplit/shared-module";

export type HandlingOAuthCredentialsFn = (
  userCredential: UserCredential,
) => AsyncResult<boolean>;

export enum PROVIDER_NAMES {
  SGS = "sgs",
  MICROSOFT = "microsoft",
  AUTH0 = "auth0",
}
