import {
  OAuthProvider,
  type UserCredential,
  type AuthError,
  type User,
} from "firebase/auth";
import {FirebaseAuthService} from "@/domains/auth/services/firebaseAuth.service";
import {type HandlingOAuthCredentialsFn} from "@/domains/auth/types";
import {type AsyncResult, Result} from "@oplit/shared-module";

// Generic OAuthService for handling different OAuth providers
export class GenericOAuthService {
  protected provider: OAuthProvider;
  protected handleCredentialsFn: HandlingOAuthCredentialsFn | null;

  constructor(
    providerId: string,
    handleCredentialsFn: HandlingOAuthCredentialsFn | null = null,
  ) {
    this.provider = new OAuthProvider(providerId);
    this.handleCredentialsFn = handleCredentialsFn;
  }

  private async linkWithPopup(): AsyncResult<UserCredential, AuthError> {
    return await FirebaseAuthService.linkWithPopup(this.provider);
  }

  private async signInWithPopup(): AsyncResult<UserCredential, AuthError> {
    return await FirebaseAuthService.signInWithPopup(this.provider);
  }

  async unlinkAccount(): AsyncResult<User> {
    return await FirebaseAuthService.unlink(this.provider.providerId);
  }

  async initSignIn(): AsyncResult<boolean, AuthError | Error> {
    const [result, error] = await this.signInWithPopup();
    if (error) return Result.error(error);

    if (this.handleCredentialsFn) return this.handleCredentialsFn(result);
    return Result.success(true);
  }

  async linkAccount(): AsyncResult<boolean, AuthError | Error> {
    const [result, error] = await this.linkWithPopup();
    if (error) return Result.error(error);

    if (this.handleCredentialsFn) return this.handleCredentialsFn(result);
    return Result.success(true);
  }
}
