import {GenericOAuthService} from "@/domains/auth/services/genericOAuth.service";
import {type HandlingOAuthCredentialsFn} from "@/domains/auth/types";

export class MicrosoftOAuthService extends GenericOAuthService {
  constructor(handleCredentialsFn: HandlingOAuthCredentialsFn | null = null) {
    super("microsoft.com", handleCredentialsFn);

    this.provider.setCustomParameters({
      tenant: "common",
    });
  }
}
