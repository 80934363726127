<template>
  <div class="left-side-bar">
    <v-list class="d-flex flex-column gap-8 py-0 mt-4">
      <slot name="before-top-list" />

      <v-list-item
        v-for="link in sidebarLinks"
        :key="link.id"
        :id="link.id"
        :to="link.route"
        :class="{'separator-item': !link.id}"
        color="newPrimaryRegular"
        rounded="lg"
        :data-testid="link.testid"
        @click="() => onLinkClick(link)"
      >
        <div v-if="!link.id" />
        <v-list-item-title v-else class="fd-flex-center gap-6">
          <OplitIcon
            v-if="link.useOplitIcon"
            :name="link.icon"
            :class="link.id"
            fill="currentColor"
            size="24"
            style="flex: 0 0 24px"
          />
          <vue-feather v-else :type="link.icon" size="24" tag="div" />

          <div v-if="!mini">{{ $t(`Navbar.${link.text}`) }}</div>
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <div class="d-flex flex-column gap-8 mb-4">
      <slot name="before-bottom-list" />

      <v-list-item
        v-for="action in sidebarActions"
        :key="action.text"
        :class="{'v-list-item--active': complementActiveState(action)}"
        rounded="lg"
        :data-testid="action.testid"
        @click="action.onClick"
      >
        <v-list-item-title class="fd-flex-center gap-6">
          <vue-feather :type="action.icon" size="24" tag="div" />

          <div v-if="!mini">{{ action.text }}</div>
        </v-list-item-title>
      </v-list-item>
      <HelpdeskLink v-if="!hasOnlySideModules">
        <v-list-item class="cursor-pointer">
          <v-list-item-title class="fd-flex-center gap-6">
            <vue-feather type="help-circle" size="24" tag="div" />
            <div>FAQ</div>
          </v-list-item-title>
        </v-list-item>
      </HelpdeskLink>

      <v-menu v-model="userMenu" :attach="$el" location="top" max-width="196px">
        <template v-slot:activator="{props}">
          <div
            v-bind="props"
            class="fd-flex-center cursor-pointer gap-6"
            data-testid="userdata-menu"
          >
            <v-avatar class="icon-wrapper" size="24">
              <v-img
                v-if="(userData || {}).avatar_url"
                :src="userData.avatar_url"
                cover
              />
              <div v-else :class="placeholderColor">
                {{ computedInitials }}
              </div>
            </v-avatar>

            <div class="profile-name text-ellipsis flex-1 fbody-1">
              {{ (userData && userData.first_name) || "" }}
            </div>

            <vue-feather
              tag="span"
              :type="`chevron-${userMenu ? 'up' : 'down'}`"
              :stroke="variables.blanc"
              class="pr-3"
              size="20"
            />
          </div>
        </template>

        <v-list>
          <v-list-item
            v-for="item in navItems"
            :key="item.text"
            :to="item.route"
            class="user-actions"
            rounded="lg"
            :data-testid="item.text"
            @click="() => (item.onClick ? item.onClick() : null)"
          >
            <v-list-item-title>
              {{ $t(`Navbar.${item.text}`) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script lang="ts">
import {
  PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING,
  PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING,
  PARAMETERS_ROOT_ROUTE_NAME,
} from "@/router/constants/parameters";
import _ from "lodash";
import {storeToRefs} from "pinia";
import {useOverloadedPerimeters} from "@/composables/useOverloadedPerimeters";
import {
  computeInitials,
  computePlaceholderColor,
  levelCorresp,
} from "@oplit/shared-module";
import {defineComponent, inject, ref} from "vue";
import {TEST_IDS} from "@/config/constants";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {filterOnPermissions} from "@/tscript/utils/permissions";
import {NavigationLink, PermissionsItem, Segment, User} from "@/interfaces";
import {useMainStore} from "@/stores/mainStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useConnexionUser} from "@/lib/composables/useConnexionUser";
import HelpdeskLink from "@/components/Global/Homemade/Commons/HelpdeskLink.vue";

import type {RouteLocation} from "vue-router";
import {environment} from "@/firebase/init";
import {useUserStore} from "@/stores/userStore";
import {useI18n} from "vue-i18n";
import {useRouteAssertions} from "@/composables/useRouteAssertions";

const LINKS_IDS = {
  ANALYTICS: "dashboard",
  HOME: "homepage",
  PARAMETERS: "parameters",
  PDP: "planification",
  SCHEDULING: "piloting",
  CUSTOMER_INTERFACE: "customer_interface",
};

export default defineComponent({
  name: "LeftSideBar",
  props: {
    mini: {type: Boolean, default: false},
  },
  components: {
    HelpdeskLink,
  },
  setup() {
    const {overloadPerimeters} = useOverloadedPerimeters();
    const {currentPermissions} = storeToRefs(usePermissionsStore());
    const {isPiloting} = storeToRefs(useSchedulingStore());
    const {isOplitAdmin} = storeToRefs(useUserStore());
    const {t} = useI18n();

    const mainStore = useMainStore();
    const {
      clientParameters,
      isDevEnv,
      isScheduling,
      simulation,
      userData,
      variables,
      hasOnlySideModules,
      hasFullAccessPDP,
      hasFullAccessScheduling,
      hasFullAccessCustomerInterfaceCustomerSide,
    } = storeToRefs(mainStore);
    const {logOut} = useConnexionUser();
    const {isParametersRoute} = useRouteAssertions();

    const segment = inject<Segment>("segment");

    function onLinkClick({id}: PermissionsItem<NavigationLink>) {
      switch (id) {
        case LINKS_IDS.ANALYTICS:
          return segment.value.track("[General] Analytics Clicked");

        case LINKS_IDS.HOME:
          return segment.value.track("[General] Homepage Clicked");

        case LINKS_IDS.PARAMETERS:
          return segment.value.track("[General] Parameters Clicked");

        case LINKS_IDS.PDP:
          return segment.value.track("[PDP] PDP Clicked");

        case LINKS_IDS.SCHEDULING:
          return segment.value.track("[Ordo] Ordo Clicked");

        case LINKS_IDS.CUSTOMER_INTERFACE:
          return segment.value.track(
            "[CustomerInterface] Customer interface Clicked",
          );

        default:
          return;
      }
    }
    function complementActiveState(item: NavigationLink): boolean {
      return (
        item.text === t("SettingsDropdown.Parametres") &&
        isParametersRoute.value
      );
    }

    return {
      levelCorresp,
      favoriteData: ref<unknown[]>([]),
      userMenu: ref<boolean>(false),
      currentPermissions,
      overloadPerimeters,
      clientParameters,
      isDevEnv,
      isScheduling,
      simulation,
      userData,
      variables,
      logOut,
      isPiloting,
      isOplitAdmin,
      onLinkClick,
      hasOnlySideModules,
      hasFullAccessPDP,
      hasFullAccessScheduling,
      hasFullAccessCustomerInterfaceCustomerSide,
      complementActiveState,
    };
  },
  computed: {
    sidebarActions(): PermissionsItem<NavigationLink>[] {
      if (
        this.hasOnlySideModules ||
        !this.currentPermissions.general.read_parameters
      )
        return [];

      const actions = [
        {
          text: this.$t("SettingsDropdown.Parametres"),
          icon: "settings",
          testid: TEST_IDS.LEFT_SIDEBAR__PARAMETERS,
          onClick: this.goToParameters,
        },
      ];
      return actions;
    },
    sidebarLinks(): PermissionsItem<NavigationLink>[] {
      const {
        hasFullAccessPDP,
        hasFullAccessScheduling,
        hasFullAccessCustomerInterfaceCustomerSide,
      } = this;

      const links: PermissionsItem<NavigationLink>[] = this.hasOnlySideModules
        ? []
        : [
            {
              id: LINKS_IDS.HOME,
              text: "Homepage",
              route: this.getRouteObject("home"),
              icon: "home",
            },
            {
              id: LINKS_IDS.ANALYTICS,
              text: "Analytics",
              route: this.getRouteObject("dashboard"),
              icon: "bar-chart-2",
              permissions: [this.currentPermissions.general.read_analytics],
              testid: TEST_IDS.LEFT_SIDEBAR__DASHBOARD,
            },
            {
              id: "plan-action",
              text: "Plan",
              route: this.getRouteObject("planaction"),
              icon: "check-circle",
              permissions: [
                this.currentPermissions.general.create_read_update_action,
              ],
              testid: TEST_IDS.LEFT_SIDEBAR__ACTION_PLAN,
            },
            {id: null}, // logic to generate a separator within the <template>
          ];
      if (!this.hasOnlySideModules && hasFullAccessPDP) {
        links.push({
          id: LINKS_IDS.PDP,
          text: "Planification",
          route: this.getRouteObject("simulation"),
          icon: "sliders",
          testid: TEST_IDS.LEFT_SIDEBAR__PLANNING,
        });
      }
      if (!this.hasOnlySideModules && hasFullAccessScheduling) {
        const route = this.isPiloting
          ? this.getRouteObject("scheduling-piloting", {
              gtab: 0,
            })
          : this.getRouteObject("scheduling-planning", {
              gtab: 0,
            });
        links.push({
          id: LINKS_IDS.SCHEDULING,
          text: "Ordo",
          route,
          icon: "crosshair",
        });
      }
      if (hasFullAccessCustomerInterfaceCustomerSide) {
        links.push({
          id: LINKS_IDS.CUSTOMER_INTERFACE,
          text: "Customer-interface",
          route: this.getRouteObject("customer-interface"),
          icon: "handshake",
          testid: TEST_IDS.LEFT_SIDEBAR__CUSTOMER_INTERFACE,
          useOplitIcon: true,
        });
      }

      return _.uniqBy(filterOnPermissions(links), "id");
    },
    navItems(): NavigationLink[] {
      const {userData, isDevEnv, isOplitAdmin} = this;
      const adminMenus: NavigationLink[] = isOplitAdmin
        ? [
            {text: "admin", route: this.getRouteObject("admin", {gtab: 0})},
            {
              text: "List-absolute-ofs",
              route: this.getRouteObject("list-absolute-ofs"),
            },
          ]
        : [];
      const devMenu: NavigationLink[] = isDevEnv
        ? [{text: "dev", route: this.getRouteObject("dev")}]
        : [];
      const teamKpisMenu: NavigationLink[] =
        environment === "staging" && isOplitAdmin
          ? [
              {
                text: "Tech-performance",
                route: this.getRouteObject("tech-performance"),
              },
            ]
          : [];
      const techMenu: NavigationLink[] =
        isOplitAdmin && environment === "staging"
          ? [{text: "KPI", route: this.getRouteObject("tech-kpis")}]
          : [];
      return [
        {
          text: "Mon compte",
          route: {
            name: "profile",
            query: this.$route.query || {},
            params: {id: _.get(userData, "id", "test"), redirect: "false"},
          },
        },
        ...adminMenus,
        ...devMenu,
        ...teamKpisMenu,
        ...techMenu,
        {text: "Déconnexion", onClick: this.logout},
      ];
    },
    computedInitials() {
      return computeInitials(this.userData);
    },
    placeholderColor() {
      const computedColor = computePlaceholderColor(
        this.userData,
        this.variables,
      );
      return `icon-placeholder cursor-pointer text-caption ${computedColor}`;
    },
  },
  watch: {
    userData: {
      immediate: true,
      handler: function (val: any) {
        this.loadData(val);
      },
    },
    mini(newBoolean: boolean): void {
      // closes this.userMenu on drawer minimization
      if (newBoolean) this.userMenu = false;
    },
  },
  methods: {
    getRouteObject(routeName: string, parameters = {}): Partial<RouteLocation> {
      const query = {};
      Object.keys({...this.$route.query, ...parameters}).forEach((key) => {
        query[key] = parameters[key] ?? this.$route.query[key];
      });
      return {name: routeName, query};
    },
    goToNewSimulation(): void {
      const routeName: string =
        this.clientParameters.has_module_scheduling &&
        this.userData?.has_scheduling &&
        this.is_scheduling
          ? "schedulingNewsimulation"
          : "newsimulation";
      if (this.$route.name !== routeName) this.$router.push({name: routeName});
    },
    goToParameters(): void {
      const firstPathMember: string = this.$route.path
        .slice(1)
        .split("/")
        .shift();
      if (firstPathMember !== PARAMETERS_ROOT_ROUTE_NAME) {
        this.onLinkClick({id: LINKS_IDS.PARAMETERS});

        this.$router.push(
          this.isScheduling
            ? this.getRouteObject(
                PARAMETERS_GENERAL_ROUTES_NAMES_MAPPING.root,
                {
                  gtab: 0,
                },
              )
            : this.getRouteObject(
                PARAMETERS_BY_SECTOR_ROUTES_NAMES_MAPPING.root,
                {
                  gtab: 1,
                },
              ),
        );
      }
    },
    loadData(userData: User) {
      if (!this.userData?.id) return false;
      this.isClientAdmin = userData.role === "CLIENT_ADMIN";
      this.langs = this.getLanguageData();
    },
    getLanguageData() {
      const languageData = [
        {
          langISO: "fr",
          langCode: "fr",
          text: this.$t("navbar.french"),
        },
        {
          langISO: "en",
          langCode: "gb",
          text: this.$t("navbar.english"),
        },
      ];
      return languageData;
    },
    logout() {
      this.logOut().then(() => {
        this.$router.push(this.getRouteObject("login"));
      });
    },
  },
});
</script>

<style scoped lang="scss">
.left-side-bar {
  background-color: rgb(var(--v-theme-newLayerBackground));
  display: flex;
  gap: 24px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;

  .v-list-item-title {
    flex: 1 1 100%;
  }
  :deep(.v-list-item) {
    & .customer_interface svg {
      fill: rgb(var(--v-theme-blanc));
      stroke: rgb(var(--v-theme-blanc));
    }
  }
}

.v-menu__content {
  max-height: none !important;
}

.v-list-item-title .vue-feather {
  overflow: visible;
}
</style>

<style lang="scss">
@import "@/scss/colors.scss";

/**
 * listing from Navbar.leftSidebarBgColor
 */
$leftSidebarBgColors: "newPrimaryDark2", "newOrangeRegular";
// .first-level-group
.v-list-group.v-list-group--sub-group.v-list-group--active {
  .v-list-item__icon.v-list-group__header__prepend-icon .v-icon {
    transform: none;
  }
  &.second-level-group
    .v-list-item__icon.v-list-group__header__prepend-icon
    .v-icon {
    transform: rotate(-180deg);
  }
  &.first-level-group
    > *:first-child
    .v-list-item__icon.v-list-group__header__prepend-icon
    .v-icon {
    transform: rotate(-180deg);
  }
}
.cache {
  z-index: 10;
}
div.v-application,
div.v-overlay-container {
  .left-side-bar {
    background-color: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .icon-wrapper {
      margin-left: 10px;

      &::before {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-radius: inherit;
        border: 3px solid rgb(var(--v-theme-newLayerBackground));
        z-index: 1;
      }
    }

    & .profile-name {
      color: rgb(var(--v-theme-blanc));
    }

    & .separator-item {
      position: relative;
      min-height: 0px;
      padding: 0;
      border-bottom: 1px solid rgb(var(--v-theme-newAppBackground));

      &::before {
        content: "";
        position: absolute;
        left: 12px;
        top: 0;
        background: rgb(var(--v-theme-newLayerBackground));
        opacity: 0.6;
        width: calc(100% - 24px);
        height: 1px;
      }
    }

    & .v-list-item:not(.user-actions):not(.faq-link):not(.separator-item) {
      color: rgb(var(--v-theme-blanc));
      /**
      * for proper display, the x padding value has to be :
      * 68 - 24 (sidebar width - sidebar xPadding) = 44
      * (44 - iconSize (20)) / 2 = xPadding
      */
      padding: 0 10px;
      min-height: 44px;

      &:focus::before {
        opacity: 0;
      }

      &:hover::before {
        border-radius: 8px;
        opacity: 0.2;
      }
    }

    & .v-list-item--link:before {
      background: none;
    }

    & .v-list-item--active {
      background: rgb(var(--v-theme-blanc));

      & .v-list-item-title {
        font-weight: 600;
      }
    }
  }

  @each $bgColor in $leftSidebarBgColors {
    .bg-#{$bgColor} .left-side-bar {
      .v-list-item {
        &--active {
          color: rgb(var(--v-theme-#{$bgColor})) !important;

          & .customer_interface svg {
            stroke: rgb(var(--v-theme-#{$bgColor}));
            fill: rgb(var(--v-theme-#{$bgColor}));
          }

          & svg {
            stroke: rgb(var(--v-theme-#{$bgColor}));
          }
        }
      }
    }
  }
}
a {
  text-decoration: none;
}
</style>
