import {GenericOAuthService} from "@/domains/auth/services/genericOAuth.service";
import {type HandlingOAuthCredentialsFn} from "@/domains/auth/types";

export class Auth0OAuthService extends GenericOAuthService {
  constructor(handleCredentialsFn: HandlingOAuthCredentialsFn | null = null) {
    super("oidc.oplit-staging-oauth0", handleCredentialsFn);

    this.provider.addScope("openid");
    this.provider.addScope("profile");
  }
}
