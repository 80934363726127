import {
  EmailAuthProvider,
  getAuth,
  linkWithPopup,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
  unlink,
  getMultiFactorResolver,
  type OAuthProvider,
  type UserCredential,
  type AuthError,
  type User,
  type MultiFactorError,
  type MultiFactorResolver,
} from "firebase/auth";
import {type AsyncResult, Result} from "@oplit/shared-module";

export class FirebaseAuthService {
  private static auth = getAuth();

  static async signInWithPopup(
    provider: OAuthProvider,
  ): AsyncResult<UserCredential, AuthError> {
    try {
      const result = await signInWithPopup(this.auth, provider);

      return Result.success(result);
    } catch (error) {
      /* eslint-disable no-console */
      console.log("Error at #signInWithPopup", {error});

      return Result.error(error as AuthError);
    }
  }

  static async linkWithPopup(
    provider: OAuthProvider,
  ): AsyncResult<UserCredential, AuthError> {
    try {
      const result = await linkWithPopup(this.auth.currentUser, provider);

      return Result.success(result);
    } catch (error) {
      /* eslint-disable no-console */
      console.log("Error at #linkWithPopup", {error});

      return Result.error(error as AuthError);
    }
  }

  static async signInWithEmailAndPassword({
    email,
    password,
  }: {
    email?: string;
    password: string;
  }): AsyncResult<UserCredential, AuthError> {
    try {
      const result = await signInWithEmailAndPassword(
        this.auth,
        email ?? this.auth.currentUser?.email,
        password,
      );

      return Result.success(result);
    } catch (error) {
      return Result.error(error as AuthError);
    }
  }

  static getMultiFactorResolver(error: MultiFactorError): MultiFactorResolver {
    return getMultiFactorResolver(this.auth, error);
  }

  static async changeCurrentUserPassword(
    oldPassword: string,
    newPassword: string,
  ): AsyncResult<boolean, AuthError> {
    try {
      const user = this.auth.currentUser;

      const credential = EmailAuthProvider.credential(user.email, oldPassword);

      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);

      return Result.success(true);
    } catch (error) {
      return Result.error(error as AuthError);
    }
  }

  static async unlink(providerId: string): AsyncResult<User> {
    try {
      const result = await unlink(this.auth.currentUser, providerId);

      return Result.success(result);
    } catch (error) {
      /* eslint-disable no-console */
      console.log("Error at #unlink", {error});

      return Result.error(error as Error);
    }
  }
}
